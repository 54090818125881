import React, { useState } from "react";
import { Button, Typography } from "../../utils";
import Icon from "../../icons";
import Modal from "../../modal";
import * as styles from "./styles.module.scss";

const HeaderGridView = ({ gallery }) => {
  const [modal, useModal] = useState(false);
  let imgCounter = 2;

  const openModal = () => {
    useModal(true);
  };

  const closeModal = () => {
    useModal(false);
  };

  return (
    <div className={styles.bannerWrapper}>
      {gallery.length >= 5 ? (
        <div
          className={styles.bannerGallery}
          onClick={() => openModal()}
          role="button"
          tabIndex={-1}
        >
          {gallery.map((b, idx) => {
            if (idx > 4) return null;
            return (
              <picture
                key={`gallery_item_${idx}`}
                className={idx === 0 ? styles.main : null}
              >
                {/* Hack to prevent images being donwloaded while they're hidden */}
                {idx !== 0 && (
                  <source
                    srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    media="(max-width: 992px)"
                  />
                )}
                <img src={b} />
              </picture>
            );
          })}
        </div>
      ) : (
        <picture className={styles.banner}>
          <img src="https://ik.imagekit.io/spotlio/f19af63d1282d79998404fbd4f13470f_xsOMJ4PAN.jpeg?updatedAt=1641382512757" />
        </picture>
      )}
      {gallery.length > 1 && (
        <Button
          className={styles.cta}
          contentClassName={styles.contentCta}
          onClick={() => openModal()}
        >
          <Typography variant="h300-medium" component="span">
            See all {gallery.length} photos
          </Typography>
          <Icon icon="Image" size="S" />
        </Button>
      )}
      {modal && (
        <Modal closeFn={() => closeModal()} size="large" overflow={false}>
          <div className={styles.modalGallery}>
            {gallery.map((i, idx) => {
              const styls = {};

              if (idx === 0) {
                styls.gridColumn = "span 2";
                styls.gridRow = "span 2";
              } else if (imgCounter === idx) {
                styls.gridRow = "span 2";
                imgCounter += 3;
              }

              return (
                <picture key={`gallery_item_modal_${idx}`} style={styls}>
                  <img src={i} />
                </picture>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HeaderGridView;
