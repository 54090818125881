import React from "react";
import { Typography } from "../utils";
import Icon from "../icons";
import Link from "../link";
import * as styles from "./styles.module.scss";

type TrustYouProps = {
  rating: string;
  scoreDescription: string;
  reviewsCount: string;
  href: string;
};

const TrustYou = (props: TrustYouProps) => {
  const { rating, scoreDescription, reviewsCount, href } = props;

  return (
    <div className={styles.trustWrapper}>
      <div className={styles.rating}>
        <div className={styles.buttonRating}>
          <Typography variant="h600-heavy" component="span">
            {rating}
          </Typography>
        </div>
      </div>
      <div className={styles.informationWrapper}>
        <Typography
          variant="h400-heavy"
          component="span"
          className={styles.description}
        >
          {scoreDescription}
        </Typography>
        <Link className={styles.reviewsCountWrapper} url={href} target="_blank">
          <Typography
            variant="h300-heavy"
            component="span"
            className={styles.reviewsCount}
          >
            {`${reviewsCount} reviews`}
          </Typography>
          <Icon size="S" icon="ChevronRight" className={styles.icon}></Icon>
        </Link>
      </div>
    </div>
  );
};

export default TrustYou;
