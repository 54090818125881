import React, { useState, useEffect } from "react";
import { LocaleAsString } from "../../localeEngine";
import Currency from "../currency";
import Modal from "../modal";
import Icon from "../icons";
import { Button, Typography } from "../utils";
import * as styles from "./styles.module.scss";

const Banners = [
  "https://ik.imagekit.io/spotlio/f19af63d1282d79998404fbd4f13470f_xsOMJ4PAN.jpeg?updatedAt=1641382512757",
  "https://ik.imagekit.io/spotlio/f87c010857bbc5dced2d1564e6b99eaf_DNnuzaDIH.png?updatedAt=1641803430405",
  "https://ik.imagekit.io/spotlio/b56c8924be907c14408de856833e3291_SEdeIPdT-ir.png?updatedAt=1641803430102",
  "https://ik.imagekit.io/spotlio/c3d672e677f4310ccbf6451041cc88d5_IqLV5wuen.png?updatedAt=1641803429938",
  "https://ik.imagekit.io/spotlio/789a133885d697f41479174f4c085b87_-QfnMDibP2.png?updatedAt=1641803429517",
];

const SupplierCell = (unit) => {
  let imgCounter = 2;
  let description = unit.data.description;

  const [modal, useModal] = useState(false);

  const toggleModal = (state) => {
    useModal(state);
  };
  return (
    <>
      <div className={styles.cellWrapper}>
        <picture className={styles.image}>
          <img src={unit.data.image}></img>
        </picture>
        <div className={styles.information}>
          <div className={styles.leftInformation}>
            <Typography variant="h400-medium" component="h1">
              {unit.data.name}
            </Typography>
            <div className={styles.amenityWrapper}>
              {unit.data.amenities.map((amenity, idx) => {
                return (
                  <React.Fragment key={`supplier_cell_amenities_${idx}`}>
                    <Icon
                      icon={amenity.icon}
                      size="S"
                      className={`${
                        amenity.highlighted ? styles.highlighted : ""
                      }`}
                    ></Icon>
                    <Typography
                      variant="h300-medium"
                      component="span"
                      className={`${
                        amenity.highlighted ? styles.highlighted : ""
                      }`}
                    >
                      {amenity.label}
                    </Typography>
                  </React.Fragment>
                );
              })}
            </div>
            <Button
              className={styles.showMore}
              contentClassName={styles.showMoreContent}
              onClick={() => toggleModal(true)}
            >
              <Typography variant="h400-medium" component="a">
                Show Details
              </Typography>
            </Button>
          </div>

          <div className={styles.rightInformation}>
            <div className={styles.info}>
              <Typography
                variant="h200-medium"
                component="span"
                className={`${styles.greyInfo} ${styles.replace}`}
              >
                from
              </Typography>
              <Currency
                template="featuredProductSpotlioTwo"
                currency={unit.data.currency}
                price={unit.data.price}
                displayDecimals={false}
              />
              <Typography
                variant="h300-medium"
                component="span"
                className={styles.greyInfo}
              >
                1 night, 2 guests
              </Typography>
              <Typography
                variant="h200-medium"
                component="span"
                className={`${styles.greyInfo}`}
              >
                400kr/night
              </Typography>
              <Typography
                variant="h400-heavy"
                component="span"
                className={styles.discount}
              >
                -20%
              </Typography>
            </div>

            <Button
              className={styles.button}
              contentClassName={styles.buttonContent}
            >
              <Typography variant="h300-medium" component="span">
                <LocaleAsString id="rentals.modal.add_cart" />
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          closeFn={() => toggleModal(false)}
          size="fullscreen"
          overflow={false}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalGallery}>
              {Banners.map((i, idx) => {
                const styls: any = {};

                if (idx === 0) {
                  styls.gridColumn = "span 2";
                  styls.gridRow = "span 2";
                } else if (imgCounter === idx) {
                  styls.gridRow = "span 2";
                  imgCounter += 3;
                }

                return (
                  <picture key={`banner_${idx}`} style={styls}>
                    <img src={i} />
                  </picture>
                );
              })}
            </div>
            <div className={styles.modalInfo}>
              <Typography variant="h400-medium" component="h1">
                {unit.data.name}
              </Typography>
              <div className={styles.amenityWrapper}>
                {unit.data.amenities.map((amenity, idx) => {
                  return (
                    <React.Fragment key={`amenity_moda_${idx}`}>
                      <div className={`${styles.amenity}`}>
                        <Icon
                          icon={amenity.icon}
                          size="S"
                          className={`${
                            amenity.highlighted ? styles.highlighted : ""
                          }`}
                        ></Icon>
                        <Typography
                          variant="h300-medium"
                          component="span"
                          className={`${
                            amenity.highlighted ? styles.highlighted : ""
                          }`}
                        >
                          {amenity.label}
                        </Typography>
                      </div>
                      <Icon
                        icon={amenity.icon}
                        size="S"
                        className={`${styles.hideInDesktop} ${
                          amenity.highlighted ? styles.highlighted : ""
                        }`}
                      ></Icon>
                      <Typography
                        variant="h300-medium"
                        component="span"
                        className={`${styles.hideInDesktop} ${
                          amenity.highlighted ? styles.highlighted : ""
                        }`}
                      >
                        {amenity.label}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </div>
              <Typography
                variant="h300-medium"
                component="p"
                className={styles.description}
              >
                {description}
              </Typography>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SupplierCell;
