// extracted by mini-css-extract-plugin
export var header = "styles-module--header--1t7gD";
export var banner = "styles-module--banner--3M2ml";
export var bannerGallery = "styles-module--bannerGallery--18Dmh";
export var headerContent = "styles-module--headerContent--2_SRT";
export var details = "styles-module--details--3Ky5i";
export var starsRating = "styles-module--starsRating--29DfR";
export var rating = "styles-module--rating--1Le-f";
export var ratingValue = "styles-module--ratingValue--3nY7A";
export var reviews = "styles-module--reviews--1dJXT";
export var review = "styles-module--review--zTCsz";
export var pricing = "styles-module--pricing---dZhf";
export var currency = "styles-module--currency--1eTC4";
export var discountChip = "styles-module--discountChip--2B0rp";
export var button = "styles-module--button--UveBq";
export var contentButton = "styles-module--contentButton--3h7hz";
export var main = "styles-module--main--3Dygq";