// extracted by mini-css-extract-plugin
export var supplierPage = "styles-module--supplierPage--3_Hkt";
export var gridColumn = "styles-module--gridColumn--73yMk";
export var cell = "styles-module--cell--EP86O";
export var descriptionDetails = "styles-module--descriptionDetails--1kobr";
export var highlighted = "styles-module--highlighted--HwWzp";
export var locationDetails = "styles-module--locationDetails--1TVyY";
export var location = "styles-module--location--1ivzk";
export var map = "styles-module--map--8SjSW";
export var description = "styles-module--description--2ciBo";
export var descriptionButtons = "styles-module--descriptionButtons--165RM";
export var policies = "styles-module--policies--2VUNF";
export var policiesContent = "styles-module--policiesContent--2pRB0";
export var showMore = "styles-module--showMore--2CafH";
export var showMoreContent = "styles-module--showMoreContent--bMh7p";
export var deskAmenities = "styles-module--deskAmenities--2hezJ";
export var amenitiesMobile = "styles-module--amenitiesMobile--fLKcK";
export var amenities = "styles-module--amenities--2H6aj";
export var amenity = "styles-module--amenity--22p93";
export var searchInfoWrapper = "styles-module--searchInfoWrapper--1umfq";
export var editSearch = "styles-module--editSearch--2Hxlw";
export var infoModalWrapper = "styles-module--infoModalWrapper--1O7S-";
export var modalMap = "styles-module--modalMap--GYGtT";
export var mobile = "styles-module--mobile--1Y_1Q";