import React, { useState } from "react";
import moment from "moment";
import { Button, Typography } from "../../utils";
import Icon from "../../icons";
import Modal from "../../modal";
import { SingleDatePicker } from "../../forms";
import QuantityPicker from "../../quantityPicker";
import { LocaleAsString } from "../../../localeEngine";
import * as styles from "./styles.module.scss";

const SearchWidget = ({
  unitless = true,
  maxAdults = 10,
  maxChildren = 10,
  arrivalDate,
  departureDate,
  setDepartureDate,
  setArrivalDate,
  setGuest,
  setEdit,
  adults,
  children,
  setAdults,
  setChildren,
}) => {
  const [datePickerModal, setDatePickerModal] = useState("");
  const [guestsModal, setGuestsModal] = useState(false);
  const [focused, setFocused] = useState(false);
  const [selectedArrivalDate, setSelectedArrivalDate] = useState(arrivalDate);
  const [selectedDepartureDate, setSelectedDepartureDate] =
    useState(departureDate);
  const [selectedAdults, setSelectedAdults] = useState(adults);
  const [selectedChildren, setSelectedChildren] = useState(children);

  const openDatePickerModal = (date) => {
    setDatePickerModal(date);
    setFocused(true);
  };

  const closeDatePickerModal = () => {
    setDatePickerModal(false);
    setFocused(false);
  };

  const onDateChange = (value) => {
    if (datePickerModal === "arrival") {
      setSelectedDepartureDate(
        moment.utc(value).add(2, "days").format("MMM DD YYYY")
      );
      setSelectedArrivalDate(moment.utc(value).format("MMM DD YYYY"));
      setDatePickerModal("departure");
    } else {
      setSelectedDepartureDate(moment.utc(value).format("MMM DD YYYY"));
    }
  };

  const differentSelectedValues = () => {
    return !(
      arrivalDate !== selectedArrivalDate ||
      departureDate !== selectedDepartureDate ||
      adults !== selectedAdults ||
      children !== selectedChildren
    );
  };

  const setFunctions = () => {
    setGuest(selectedAdults + selectedChildren);
    setDepartureDate(selectedDepartureDate);
    setArrivalDate(selectedArrivalDate);
    setAdults(selectedAdults);
    setChildren(selectedChildren);
    setEdit && setEdit(false);
  };

  return (
    <>
      <div
        className={`${styles.searchWidget} ${unitless ? styles.unitless : ""}`}
      >
        <Button
          onClick={() => openDatePickerModal("arrival")}
          onFocus={() => openDatePickerModal("arrival")}
          className={styles.picker}
          contentClassName={styles.pickerContent}
        >
          <Typography variant="h300-medium" component="span">
            Dates
          </Typography>
          <Typography variant="h400-medium" component="span">
            {`${selectedArrivalDate} - ${selectedDepartureDate}`}
          </Typography>
          <Icon icon="Calendar" size="S" className={styles.icon} />
        </Button>
        <Button
          className={styles.picker}
          contentClassName={styles.pickerContent}
          onClick={() => setGuestsModal(true)}
          onFocus={() => setGuestsModal(true)}
        >
          <Typography variant="h300-medium" component="span">
            Add guests
          </Typography>
          <Typography variant="h400-medium" component="span">
            {`${selectedAdults} adults ${selectedChildren} children`}
          </Typography>
          <Icon icon="ChevronDown" size="S" className={styles.icon} />
        </Button>
        <Button
          className={styles.searchBtn}
          contentClassName={styles.contentSearchBtn}
          disabled={differentSelectedValues()}
          onClick={() => setFunctions()}
        >
          <Typography variant="h400-medium" component="span">
            Edit search
          </Typography>
          <Icon icon="Search" size="S" />
        </Button>
      </div>
      {datePickerModal && (
        <Modal
          closeFn={() => closeDatePickerModal()}
          size="fit-content"
          overflow={false}
        >
          <div className={styles.wrapperDatePicker}>
            <Typography
              variant="h500-medium"
              component="span"
              className={styles.date}
            >
              <LocaleAsString
                id={
                  datePickerModal === "arrival"
                    ? "lodging.search_widget.arrival_date"
                    : "lodging.search_widget.departure_date"
                }
              />
            </Typography>
            <SingleDatePicker
              minValue={moment.utc()}
              maxValue={moment.utc().add(1, "years")}
              date={moment.utc(
                datePickerModal === "arrival"
                  ? selectedArrivalDate
                  : selectedDepartureDate
              )}
              onDateChange={onDateChange}
              focused={focused}
              setFocused={
                datePickerModal === "arrival"
                  ? () => void 0
                  : closeDatePickerModal
              }
            />
          </div>
        </Modal>
      )}
      {guestsModal && (
        <Modal
          closeFn={() => setGuestsModal(false)}
          size="small"
          overflow={false}
        >
          <div className={styles.searchModal} style={{ paddingTop: "4.8rem" }}>
            <div className={styles.quantitySelector}>
              <Typography variant="h400-medium" component="span">
                {`Adult${adults > 1 ? "s" : ""}`}
              </Typography>
              <QuantityPicker
                quantity={selectedAdults}
                setQuantity={setSelectedAdults}
                minQuantity={1}
                maxQuantity={maxAdults ? maxAdults : 10}
              />
            </div>
            <div className={styles.quantitySelector}>
              <Typography variant="h400-medium" component="span">
                Children
              </Typography>
              <QuantityPicker
                quantity={selectedChildren}
                setQuantity={setSelectedChildren}
                minQuantity={0}
                maxQuantity={maxChildren ? maxChildren : 10}
              />
            </div>

            <Button
              className={styles.searchBtn}
              contentClassName={styles.contentSearchBtn}
              onClick={() => setGuestsModal(false)}
            >
              <Typography variant="h400-heavy" component="span">
                Ok
              </Typography>
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SearchWidget;
