// extracted by mini-css-extract-plugin
export var cellWrapper = "styles-module--cellWrapper--ohrfc";
export var image = "styles-module--image--3w5M0";
export var information = "styles-module--information--1AxWb";
export var leftInformation = "styles-module--leftInformation--2-xT9";
export var showMore = "styles-module--showMore--19JjZ";
export var showMoreContent = "styles-module--showMoreContent--z4CNJ";
export var description = "styles-module--description--3I2bL";
export var amenityWrapper = "styles-module--amenityWrapper--1JCG4";
export var highlighted = "styles-module--highlighted--3GIqJ";
export var rightInformation = "styles-module--rightInformation--2jF93";
export var info = "styles-module--info--PHYIm";
export var greyInfo = "styles-module--greyInfo--3Ix46";
export var discount = "styles-module--discount--36mRB";
export var button = "styles-module--button--25EVu";
export var modalContent = "styles-module--modalContent--27QTI";
export var modalInfo = "styles-module--modalInfo--iyg8v";
export var hideInDesktop = "styles-module--hideInDesktop--4S7uL";
export var modalGallery = "styles-module--modalGallery--1hddP";
export var bannerWrapper = "styles-module--bannerWrapper--3g2oC";
export var banner = "styles-module--banner--_G02R";
export var bannerGallery = "styles-module--bannerGallery--3FdOY";
export var main = "styles-module--main--2m5hh";
export var amenity = "styles-module--amenity---IJw9";
export var mobile = "styles-module--mobile--1sEVm";
export var replace = "styles-module--replace--GwENf";
export var buttonContent = "styles-module--buttonContent--tod-V";