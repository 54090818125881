import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Layout from "../../components/layout";
import { Button, Container, Typography } from "../../components/utils";
import Map from "../../components/map";
import Icon from "../../components/icons";
import Accordion from "../../components/accordion";
import SearchWidget from "../../components/lodging/searchWidgetLab";
import SupplierCell from "../../components/supplierCell";
import Header from "./header";
import Modal from "../../components/modal";
import { Icon as Icontype } from "../../components/icons/types";
import * as styles from "./styles.module.scss";

interface IAmenities {
  icon: Icontype;
  label: string;
}

const SupplierMockup = {
  units: [
    {
      name: "Holida home Bjorli",
      description:
        "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
      price: "80",
      currency: "NOK",
      image:
        "https://ik.imagekit.io/spotlio/f87c010857bbc5dced2d1564e6b99eaf_DNnuzaDIH.png?updatedAt=1641803430405",
      amenities: [
        {
          icon: "Bed2",
          label: "1 queen size bed",
        },
        {
          icon: "Parking",
          label: "Free parking",
        },
        {
          icon: "Toaster",
          label: "Breakfast included",
        },
        {
          icon: "XCircle",
          label: "Non refundable",
        },
        {
          icon: "CovidSecurity",
          label: "Covid-19 safety measures",
        },
        {
          icon: "Bell2",
          label: "24h reception",
        },
      ],
    },
    {
      name: "Holida home Lesjaskog",
      description:
        "Holiday home Lesjaskog, a property with barbecue facilities, is situated in Bjorli, 10 km from Reinheimen National Park.",
      price: "60",
      currency: "NOK",
      image:
        "https://ik.imagekit.io/spotlio/f19af63d1282d79998404fbd4f13470f_xsOMJ4PAN.jpeg?updatedAt=1641382512757",
      amenities: [
        {
          icon: "Bed2",
          label: "2 twin Beds",
        },
        {
          icon: "Parking",
          label: "Free parking",
        },
        {
          icon: "Toaster",
          label: "Breakfast included",
        },
        {
          icon: "CheckCircle",
          label: "Free cancellation",
          date: "before Thu, Dec 23",
          highlighted: true,
        },
        {
          icon: "CovidSecurity",
          label: "Covid-19 safety measures",
        },
        {
          icon: "Bell2",
          label: "24h reception",
        },
      ],
    },
    {
      name: "Holida home Lesjaskog",
      description:
        "Holiday home Lesjaskog, a property with barbecue facilities, is situated in Bjorli, 10 km from Reinheimen National Park.",
      price: "60",
      currency: "NOK",
      image:
        "https://ik.imagekit.io/spotlio/f19af63d1282d79998404fbd4f13470f_xsOMJ4PAN.jpeg?updatedAt=1641382512757",
      amenities: [
        {
          icon: "Bed2",
          label: "1 king size bed",
        },
        {
          icon: "Parking",
          label: "Free parking",
        },
        {
          icon: "Toaster",
          label: "Breakfast included",
        },
        {
          icon: "CheckCircle",
          label: "Free cancellation",
          date: "before Thu, Dec 23",
          highlighted: true,
        },
        {
          icon: "CovidSecurity",
          label: "Covid-19 safety measures",
        },
        {
          icon: "Bell2",
          label: "24h reception",
        },
      ],
    },
    {
      name: "Holida home Lesjaskog",
      description:
        "Holiday home Lesjaskog, a property with barbecue facilities, is situated in Bjorli, 10 km from Reinheimen National Park.",
      price: "60",
      currency: "NOK",
      image:
        "https://ik.imagekit.io/spotlio/789a133885d697f41479174f4c085b87_-QfnMDibP2.png?updatedAt=1641803429517",
      amenities: [
        {
          icon: "Bed2",
          label: "2 twin Beds",
        },
        {
          icon: "Parking",
          label: "Free parking",
        },
        {
          icon: "Toaster",
          label: "Breakfast included",
        },
        {
          icon: "CheckCircle",
          label: "Free cancellation",
          date: "before Thu, Dec 23",
          highlighted: true,
        },
        {
          icon: "CovidSecurity",
          label: "Covid-19 safety measures",
        },
        {
          icon: "Bell2",
          label: "24h reception",
        },
      ],
    },
    {
      name: "Holida home Lesjaskog",
      description:
        "Holiday home Lesjaskog, a property with barbecue facilities, is situated in Bjorli, 10 km from Reinheimen National Park.",
      price: "60",
      currency: "NOK",
      image:
        "https://ik.imagekit.io/spotlio/f87c010857bbc5dced2d1564e6b99eaf_DNnuzaDIH.png?updatedAt=1641803430405",
      amenities: [
        {
          icon: "Bed2",
          label: "2 twin Beds",
        },
        {
          icon: "Parking",
          label: "Free parking",
        },
        {
          icon: "Toaster",
          label: "Breakfast included",
        },
        {
          icon: "CheckCircle",
          label: "Free cancellation",
          date: "before Thu, Dec 23",
          highlighted: true,
        },
        {
          icon: "CovidSecurity",
          label: "Covid-19 safety measures",
        },
        {
          icon: "Bell2",
          label: "24h reception",
        },
      ],
    },
  ],
  amenities: ["Pool", "Wi-fi", "Lorem Ipsum"],
  policies: [
    {
      title: "Written Policies",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam reiciendis magni ut veniam alias ipsa error totam in. Nobis veniam provident, dolore excepturi facere officia ratione! Sit, corrupti doloribus",
    },
    {
      title: "Resort Policies",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam reiciendis magni ut veniam alias ipsa error totam in. Nobis veniam provident, dolore excepturi facere officia ratione! Sit, corrupti doloribus",
    },
  ],
  name: "Bjorli Example Supplier",
  latitude: 62.255725,
  longitude: 8.18054,
  stars: 4,
  address: "Gardsvegen 22, Bjorli 2669 Norway",
  fromPrice: "60",
};

const MockDatos = [
  {
    name: "Holida home Bjorli",
    description: `Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.

    With free WiFi, this holiday home features a TV, a washing machine and a kitchen with a dishwasher and microwave.
    
    Holiday home Bjorli provides a terrace.
    
    The nearest airport is Molde Airport, Årø Airport, 73 km from the accommodation.
    
    Distance in property description is calculated using © OpenStreetMap`,
    amenities: ["Wifi", "Kitchen", "Washer", "Air conditioning"],
    price: 80,
    lodgingInfo: [
      {
        value: "5-10 beds",
        icon: "Bed2",
      },
      {
        value: "50m",
        icon: "Skiing",
      },
      {
        value: "4.5-5",
        icon: "Snowflake",
      },
      {
        value: "Airport",
        icon: "Arrival",
      },
    ],
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/29cab09f-cb31-4f9b-bef1-b60193cfeac2.jpg?im_w=480",
    latitude: 62.25572,
    longitude: 8.18,
    stars: 1,
  },
  {
    name: "Holida home Lesjaskog",
    description:
      "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
    amenities: ["Wifi", "Kitchen", "Washer", "Air conditioning"],
    price: 34,
    lodgingInfo: [
      {
        value: "4-6 beds",
        icon: "Bed2",
      },
    ],
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/75413637-db01-48b0-b11f-41bf5569a243.jpg",
    latitude: 62.255725,
    longitude: 8.18054,
    stars: 4,
  },
  {
    name: "Nytt anneks",
    description:
      "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
    amenities: ["Wifi", "Kitchen", "Free parking", "Heating"],
    price: 150,
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/5082ea2a-9968-4645-a4e1-629d2cbb2eef.jpg?im_w=720",
    latitude: 62.255725,
    longitude: 8.18054,
    stars: 4,
  },
  {
    name: "Zytt anneks",
    description:
      "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
    amenities: ["Wifi", "Kitchen", "Free parking", "Heating", "Laundry"],
    price: 22,
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/5082ea2a-9968-4645-a4e1-629d2cbb2eef.jpg?im_w=720",
    stars: 5,
  },
  {
    name: "Yytt anneks",
    description:
      "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
    amenities: ["Wifi", "Kitchen", "Free parking", "Heating"],
    lodgingInfo: [
      {
        value: "4-6 beds",
        icon: "Bed2",
      },
      {
        value: "Airport",
        icon: "Arrival",
      },
    ],
    price: 132,
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/5082ea2a-9968-4645-a4e1-629d2cbb2eef.jpg?im_w=720",
    stars: 3,
  },
  {
    name: "Nytt anneks",
    description:
      "Boasting a hot tub, Holiday home Bjorli is situated in Bjorli. The holiday home is 7 km from Reinheimen National Park.",
    amenities: ["Wifi", "Kitchen", "Free parking", "Heating"],
    price: 132,
    currency: "NOK",
    image:
      "https://a0.muscache.com/im/pictures/5082ea2a-9968-4645-a4e1-629d2cbb2eef.jpg?im_w=720",
    stars: 2,
  },
];

const Amenities: IAmenities[] = [
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "Toaster",
    label: "Breakfast included",
  },
  {
    icon: "Parking",
    label: "Free parking",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
  {
    icon: "FileText",
    label: "Policies",
  },
];

const isClientSide =
  typeof window !== "undefined" || typeof document !== "undefined";

const description = `The Steamboat Grand is a full service property with a heated
  outdoor swimming pool, two large hot tubs, the Grand Spa, and on
  site dining options. This is a non-smoking property and pets are
  not allowed. The Steamboat Grand, located at the base of the
  Steamboat Ski Area, features 328 beautifully appointed guestroom
  accommodations offering a variety of hotel rooms and one, two, a
  non-smoking property and pets are not allowed. The Steamboat
  Grand, located at the base of the the Grand Spa, and on site
  dining options. The Steamboat Grand, located at the base of the
  Steamboat Ski Area, features 328 beautifully appointed guestroom
  accommodations offering a variety of hotel rooms and one, two, a
  non-smoking property and pets are not allowed. The Steamboat
  Grand, located at the base of the the Grand Spa, and on site
  dining options.`;

const SupplierPage = (props) => {
  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const [modalDescription, setModalDescription] = useState(false);
  const [modalMap, setModalMap] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const defaultArrivalDate = moment().format("MMM DD YYYY");
  const defaultDepartureDate = moment(defaultArrivalDate, "MMM DD YYYY")
    .add(3, "d")
    .format("MMM DD YYYY");
  const [arrivalDate, setArrivalDate] = useState(defaultArrivalDate);
  const [departureDate, setDepartureDate] = useState(defaultDepartureDate);
  const [guest, setGuest] = useState(2);
  const [editState, setEditState] = useState(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);

  const showDescripton = (description) => {
    let descriptionSliced = description;
    if (description.length > 520) {
      descriptionSliced = description.slice(0, 520).trim() + " ...";
    }
    if (showMore === true) {
      descriptionSliced = description;
    }
    return (
      <>
        <span>{descriptionSliced}</span>
      </>
    );
  };

  const toggleState = () => {
    if (showMore) setShowMore(false);
    if (!showMore) setShowMore(true);
  };

  useEffect(() => {
    if (description.length > 420) setDisplayButton(true);
  }, []);

  const scrollRef = useRef(null);

  return (
    // cuando lodging sea un módulo y no una página suelta, este layout sobrará
    <Layout backgroundColor="#f4f4f9" hideMobileFooter={true}>
      <Container maxWidth="lg" className={styles.supplierPage}>
        {/* Enable this search widget for suppliers without rooms
        <section>
          <SearchWidget
            arrivalDate={defaultArrivalDate}
            departureDate={defaultDepartureDate}
            setDepartureDate={setDepartureDate}
            setArrivalDate={setArrivalDate}
            setGuest={setGuest}
            setEdit={setEditState}
          />
        </section> */}
        <Header scrollRef={scrollRef} />
        <div className={styles.gridColumn}>
          <section className={`${styles.cell}`}>
            <Typography variant="h500-medium" component="span">
              Description
            </Typography>
            <p className={styles.descriptionDetails}>
              <Icon icon="Bed2" size="S" />
              <Typography variant="h300-medium" component="span">
                5-10 beds
              </Typography>

              <Icon
                icon="CheckCircle"
                size="S"
                className={styles.highlighted}
              />
              <Typography
                variant="h300-medium"
                component="span"
                className={styles.highlighted}
              >
                Free cancellation
              </Typography>

              <Icon icon="XCircle" size="S" />
              <Typography variant="h300-medium" component="span">
                Non-refundable
              </Typography>

              <Icon
                icon="CheckCircle"
                size="S"
                className={styles.highlighted}
              />
              <Typography
                variant="h300-medium"
                component="span"
                className={styles.highlighted}
              >
                Partially refundable
              </Typography>

              <Icon icon="CovidSecurity" size="S" />
              <Typography variant="h300-medium" component="span">
                Covid-19 safety measures
              </Typography>
            </p>

            <Typography
              component="p"
              variant="h300-heavy"
              className={`${styles.description}`}
            >
              {showDescripton(description)}
            </Typography>

            <div className={styles.descriptionButtons}>
              <Button
                className={styles.policies}
                contentClassName={styles.policiesContent}
                onClick={() => setModalDescription(true)}
              >
                <Typography variant="h300-medium" component="span">
                  Policies
                </Typography>
                <Icon icon="FileText" size="S" />
              </Button>
              {displayButton && (
                <Button
                  className={styles.showMore}
                  contentClassName={styles.showMoreContent}
                  onClick={() => toggleState()}
                >
                  <Typography variant="h300-medium" component="span">
                    {showMore ? "Show less" : "Show more"}
                  </Typography>
                  <Icon
                    icon={showMore ? "MinusCircle" : "PlusCircle"}
                    size="S"
                  />
                </Button>
              )}
            </div>
          </section>

          <section className={styles.cell}>
            <Typography variant="h500-medium" component="span">
              Location
            </Typography>
            <p className={styles.locationDetails}>
              <Icon icon="Skiing" size="S" />
              <Typography variant="h300-medium" component="span">
                50m
              </Typography>

              <Icon icon="Center" size="S" />
              <Typography variant="h300-medium" component="span">
                500m
              </Typography>

              <Icon icon="Flights" size="S" />
              <Typography variant="h300-medium" component="span">
                5km
              </Typography>
            </p>
            <div onClick={() => setModalMap(true)}>
              <Map className={styles.map} suppliers={MockDatos} />
            </div>

            <Typography
              variant="h300-medium"
              component="span"
              className={styles.location}
            >
              Skriuvegen 6, 2669 Bjorli, Norway
            </Typography>
            <Button
              className={styles.showMore}
              contentClassName={styles.showMoreContent}
              onClick={() => setModalMap(true)}
            >
              <Typography variant="h300-medium" component="span">
                Show Map
              </Typography>
            </Button>
          </section>
        </div>

        <section className={styles.cell}>
          <Typography variant="h500-medium" component="span">
            Amenities
          </Typography>
          <div className={styles.amenitiesMobile}>
            <div className={styles.amenities}>
              {Amenities.map((a, idx) => {
                const { icon, label } = a;
                if (idx >= 8) return null;
                return (
                  <span key={`amenities_${idx}`} className={styles.amenity}>
                    <Icon icon={icon} size="S" />
                    <Typography variant="h300-medium" component="span">
                      {label}
                    </Typography>
                  </span>
                );
              })}
            </div>
            <Accordion
              className={styles.amenities}
              open={showAllAmenities}
              responsive={true}
            >
              {Amenities.map((a, idx) => {
                const { icon, label } = a;
                if (idx < 8) return null;
                return (
                  <span
                    key={`amenities_accordion_${idx}`}
                    className={styles.amenity}
                  >
                    <Icon icon={icon} size="S" />
                    <Typography variant="h300-medium" component="span">
                      {label}
                    </Typography>
                  </span>
                );
              })}
            </Accordion>
          </div>
          <div className={styles.deskAmenities}>
            {Amenities.map((a, idx) => {
              const { icon, label } = a;
              return (
                <span key={`amenities_desk_${idx}`} className={styles.amenity}>
                  <Icon icon={icon} size="S" />
                  <Typography variant="h300-medium" component="span">
                    {label}
                  </Typography>
                </span>
              );
            })}
          </div>
          {Amenities.length > 8 && (
            <Button
              className={`${styles.showMore} ${styles.mobile}`}
              contentClassName={styles.showMoreContent}
              onClick={() => setShowAllAmenities(!showAllAmenities)}
            >
              <Typography variant="h300-medium" component="span">
                {!showAllAmenities
                  ? "Show all " + Amenities.length
                  : "Show less"}
              </Typography>
              {!showAllAmenities ? (
                <Icon icon="ChevronDown" size="S" />
              ) : (
                <Icon icon="ChevronUp" size="S" />
              )}
            </Button>
          )}
        </section>

        <section className={styles.cell} ref={scrollRef}>
          <Typography variant="h500-medium" component="span">
            Select your room
          </Typography>
          {editState ? (
            <SearchWidget
              unitless={false}
              arrivalDate={arrivalDate}
              departureDate={departureDate}
              adults={adults}
              children={children}
              setAdults={setAdults}
              setChildren={setChildren}
              setDepartureDate={setDepartureDate}
              setArrivalDate={setArrivalDate}
              setGuest={setGuest}
              setEdit={setEditState}
            />
          ) : (
            <p className={styles.searchInfoWrapper}>
              <Typography component="span" variant="h400-medium">
                Showing {MockDatos.length - 1} rooms for search{" "}
                {moment(arrivalDate).format("MMM DD ")} to{" "}
                {moment(departureDate).format("MMM DD")} for {guest} guests.
              </Typography>
              <span
                className={styles.editSearch}
                onClick={() => setEditState(true)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  const { code } = e;
                  if (code === "Space" || code === "Enter") setEditState(true);
                }}
              >
                <Typography variant="h300-medium" component="span">
                  Edit search
                </Typography>
              </span>
            </p>
          )}
          {SupplierMockup.units.map((unit, idx) => {
            return <SupplierCell key={`supplier_unit_${idx}`} data={unit} />;
          })}
        </section>
        {modalDescription && (
          <Modal
            closeFn={() => setModalDescription(false)}
            size="small"
            overflow={false}
          >
            <div className={styles.infoModalWrapper}>
              <Typography variant="h500-medium" component="span">
                Description
              </Typography>
              <p className={styles.descriptionDetails}>
                <Icon icon="Bed2" size="S" />
                <Typography variant="h300-medium" component="span">
                  5-10 beds
                </Typography>

                <Icon
                  icon="CheckCircle"
                  size="S"
                  className={styles.highlighted}
                />
                <Typography
                  variant="h300-medium"
                  component="span"
                  className={styles.highlighted}
                >
                  Free cancellation
                </Typography>

                <Icon icon="XCircle" size="S" />
                <Typography variant="h300-medium" component="span">
                  Non-refundable
                </Typography>

                <Icon
                  icon="CheckCircle"
                  size="S"
                  className={styles.highlighted}
                />
                <Typography
                  variant="h300-medium"
                  component="span"
                  className={styles.highlighted}
                >
                  Partially refundable
                </Typography>

                <Icon icon="CovidSecurity" size="S" />
                <Typography variant="h300-medium" component="span">
                  Covid-19 safety measures
                </Typography>
              </p>

              <Typography
                component="p"
                variant="h300-heavy"
                className={styles.description}
              >
                The Steamboat Grand is a full service property with a heated
                outdoor swimming pool, two large hot tubs, the Grand Spa, and on
                site dining options. This is a non-smoking property and pets are
                not allowed. The Steamboat Grand, located at the base of the
                Steamboat Ski Area, features 328 beautifully appointed guestroom
                accommodations offering a variety of hotel rooms and one, two, a
                non-smoking property and pets are not allowed. The Steamboat
                Grand, located at the base of the the Grand Spa, and on site
                dining options.
              </Typography>

              <div className={styles.descriptionButtons}></div>
            </div>
          </Modal>
        )}
        {modalMap && (
          <Modal
            size="fullscreen"
            closeFn={() => setModalMap(false)}
            overflow={false}
          >
            <section className={styles.modalMap}>
              <Typography variant="h500-medium" component="span">
                Location
              </Typography>
              <p className={styles.locationDetails}>
                <Icon icon="Skiing" size="S" />
                <Typography variant="h300-medium" component="span">
                  50m
                </Typography>

                <Icon icon="Center" size="S" />
                <Typography variant="h300-medium" component="span">
                  500m
                </Typography>

                <Icon icon="Flights" size="S" />
                <Typography variant="h300-medium" component="span">
                  5km
                </Typography>
              </p>
              <Map className={styles.map} suppliers={MockDatos} />
              <Typography
                variant="h300-medium"
                component="span"
                className={styles.location}
              >
                Skriuvegen 6, 2669 Bjorli, Norway
              </Typography>
            </section>
          </Modal>
        )}
      </Container>
    </Layout>
  );
};

export default SupplierPage;
