import React from "react";
import Icon from "../../../components/icons";
import HeaderGridView from "../../../components/supplierPage/headerGridView";
import { Button, Typography } from "../../../components/utils";
import Currency from "../../../components/currency";
import TrustYou from "../../../components/trustYou";
import * as styles from "./styles.module.scss";

const Banners = [
  "https://ik.imagekit.io/spotlio/f19af63d1282d79998404fbd4f13470f_xsOMJ4PAN.jpeg?updatedAt=1641382512757",
  "https://ik.imagekit.io/spotlio/f87c010857bbc5dced2d1564e6b99eaf_DNnuzaDIH.png?updatedAt=1641803430405",
  "https://ik.imagekit.io/spotlio/b56c8924be907c14408de856833e3291_SEdeIPdT-ir.png?updatedAt=1641803430102",
  "https://ik.imagekit.io/spotlio/c3d672e677f4310ccbf6451041cc88d5_IqLV5wuen.png?updatedAt=1641803429938",
  "https://ik.imagekit.io/spotlio/789a133885d697f41479174f4c085b87_-QfnMDibP2.png?updatedAt=1641803429517",
];

const Header = ({ scrollRef }) => {
  return (
    <section className={styles.header}>
      <HeaderGridView gallery={Banners} />
      <div className={styles.headerContent}>
        <div className={styles.details}>
          <Typography component="h2" variant="h600-medium">
            Oriental Phoenix Peninsula Beach Hotel & Spa
          </Typography>
          <div className={styles.starsRating}>
            <Icon icon="Star" size="S" />
            <Icon icon="Star" size="S" />
            <Icon icon="Star" size="S" />
            <Icon icon="Star" size="S" />
            <Icon icon="Star" size="S" />
          </div>
          <TrustYou
            reviewsCount="1910"
            rating="4.2"
            scoreDescription="Very Good"
            href="#"
          />
        </div>

        <div className={styles.pricing}>
          <Typography variant="h300-medium" component="p">
            from
            <br />
            <Currency
              currency="kr"
              price={800}
              template="checkout"
              className={styles.currency}
            />
            <br />
            1 night, 2 guests
            <br />
            400 kr/night
          </Typography>
          <div className={styles.discountChip}>
            <Typography variant="h400-heavy" component="span">
              -25%
            </Typography>
          </div>
        </div>
      </div>
      <Button
        contentClassName={styles.contentButton}
        className={styles.button}
        onClick={() => scrollRef.current.scrollIntoView()}
      >
        <Typography component="span" variant="h400-medium">
          Book Now
        </Typography>
      </Button>
    </section>
  );
};
export default Header;
